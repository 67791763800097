$bgcolor-solid: #f1f1f1;
$bgcolor-trans: rgba(200, 200, 200, 0.8);

body {
  margin: 0;
  padding: 0;
  font-family: 'Barlow Condensed', Roboto, sans-serif;
  background-color: $bgcolor-solid;
}

html,
body {
  height: 100%;
}

span.siteLogo,
span.camera_svg,
span.eye_svg,
span.add_comment_svg,
span.mode_comment_svg,
span.favorite_svg,
span.favorite_border_svg,
span.iso,
span.focal_length,
span.mastodonLogo,
span.flickrLogo,
span.instagramLogo,
span.bandcampLogo,
span.appleSignIn,
span.facebookSignIn,
span.githubSignIn,
span.googleSignIn,
span.hotmailSignIn,
span.microsoft_blackSignIn,
span.microsoft_logo2SignIn,
span.phoneSignIn,
span.twitterSignIn,
span.yahooSignIn,
span.anonymousSignIn {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  width: 30px;
}

span.appleSignIn {
  background-image: url(/assets/apple_bw.svg);
}
span.facebookSignIn {
  background-image: url(/assets/facebook.svg);
}
span.githubSignIn {
  background-image: url(/assets/github.svg);
}
span.googleSignIn {
  background-image: url(/assets/google.svg);
}
span.hotmailSignIn {
  background-image: url(/assets/microsoft_bw.svg);
}
span.microsoft_blackSignIn {
  background-image: url(/assets/microsoft_black.svg);
}
span.microsoft_logo2SignIn {
  background-image: url(/assets/microsoft_logo2.svg);
}
span.phoneSignIn {
  background-image: url(/assets/phone.svg);
}
span.twitterSignIn {
  background-image: url(/assets/twitter.svg);
}
span.yahooSignIn {
  background-image: url(/assets/yahoo_Y.svg);
}
span.anonymousSignIn {
  background-image: url(/assets/baseline_person.svg);
}
span.focal_length {
  background-image: url(/graphics/icons/focal_length.svg);
}
span.iso {
  background-image: url(/graphics/icons/iso.svg);
}
span.camera_svg {
  background-image: url(/graphics/icons/camera.svg);
}
span.eye_svg {
  background-image: url(/graphics/icons/eye_white_24dp.svg);
}
span.add_comment_svg {
  background-image: url(/graphics/icons/add_comment_white_24dp.svg);
}
span.mode_comment_svg {
  background-image: url(/graphics/icons/mode_comment_white_24dp.svg);
}
span.favorite_svg {
  background-image: url(/graphics/icons/favorite_white_24dp.svg);
}
span.favorite_border_svg {
  background-image: url(/graphics/icons/favorite_border_white_24dp.svg);
}
span.mastodonLogo {
  background-image: url(/assets/mastodon.svg);
}
span.flickrLogo {
  background-image: url(/assets/flickr.svg);
}
span.instagramLogo {
  background-image: url(/assets/instagram.svg);
}
span.bandcampLogo {
  background-image: url(/assets/bandcamp.svg);
}
span.siteLogo {
  background-image: url(/graphics/logos/favicon-16x16.png);
}

.app-full-height-dialog .mat-dialog-container {
  padding: 2px 24px;
}
.app-custom-bgcolor-dialog .mat-dialog-container {
  background-color: #181818;
}
